import classnames from 'classnames';
import { rem } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import {
  colors,
  mediaQueryMax,
  mediaQueryMin,
  screens,
  spacing,
  transition,
} from '../../../styles/theme';
import Link from '../Link';
import Logo from '../Logo';
import AuthBoxBody from './Body';
import AuthBoxHead from './Head';

const AuthBoxEl = styled.div`
  width: 100%;
  max-width: ${rem(840)};
  overflow: hidden;
  background: ${colors.light['300']};
`;

const LinkEl = styled(Link)`
  display: block;
  position: absolute;
  top: ${spacing.l};
  transition: opacity ${transition.timing.base} ${transition.easing.base};
  &:hover,
  &:focus {
    opacity: 0.5;
  }

  ${mediaQueryMax(screens.tablet)} {
    left: 50%;
    transform: translateX(-50%);
  }

  ${mediaQueryMin(screens.tablet)} {
    left: ${spacing.l};
  }
`;

const AuthBox: FC<{
  aside?: ReactNode;
  standard?: boolean;
}> = ({ aside, children, standard }) => {
  const Aside: any = aside;

  return (
    <AuthBoxEl
      className={classnames(
        'relative flex flex-col tablet:flex-row',
        'tablet:shadow-base tablet:rounded-md',
        'tablet:border-solid tablet:border tablet:border-neutral-lighter',
      )}
    >
      <LinkEl className="" to="/">
        <Logo
          className="text-primary-500 tablet:text-light-300"
          height={39}
          standard={standard}
          width={121}
        />
      </LinkEl>
      {'function' === typeof aside && (
        <AuthBoxHead>
          <Aside />
        </AuthBoxHead>
      )}
      <AuthBoxBody>{children}</AuthBoxBody>
    </AuthBoxEl>
  );
};

export default AuthBox;
