import classnames from 'classnames';
import React, { FC } from 'react';
import styled from 'styled-components';

const AuthBoxBodySC = styled.div`
  flex-grow: 1;
`;

const AuthBoxBody: FC = ({ children }) => (
  <AuthBoxBodySC
    className={classnames(
      'flex items-center justify-center bg-light-300',
      'tablet:order-none order-first',
      'pt-32 px-12 pb-16 tablet:py-32 tablet:px-xl',
    )}
  >
    {children}
  </AuthBoxBodySC>
);

export default AuthBoxBody;
