import classnames from 'classnames';
import React, { FC } from 'react';

const AuthBoxHead: FC = ({ children }) => (
  <div
    className={classnames(
      'block tablet:flex tablet:justify-center tablet:items-center tablet:flex-shrink',
      'text-center text-light-300 p-l',
      'tablet:px-xl tablet:py-32 tablet:w-72',
      'bg-primary-dark admin:bg-neutral-dark',
    )}
  >
    {children}
  </div>
);
export default AuthBoxHead;
