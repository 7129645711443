import { navigate } from 'gatsby';
import React, { FC, useEffect } from 'react';

import AuthBox from '../components/base/AuthBox';
import FullPageCenter from '../components/base/FullPageCenter';
import SignOn from '../components/base/SignOn';
import LoginForm from '../components/form-old/Login';
import App from '../components/layout/App';
import { auth } from '../firebase';
import pageProps, { ResultProps } from '../utils/pageProps';

const LoginPage: FC<ResultProps> = ({ params: { confirm } }) => {
  useEffect(() => {
    if ('undefined' !== typeof window && auth?.currentUser) {
      navigate(String(process.env.GATSBY_REDIRECT_LOGED));
    }
  }, []);

  return (
    <App naked>
      <FullPageCenter>
        <AuthBox aside={SignOn}>
          <LoginForm />
        </AuthBox>
      </FullPageCenter>
    </App>
  );
};

export default pageProps(LoginPage);
