import React, { FC } from 'react';

interface QueryProps {
  location?: {
    search: string;
  };
}

export interface ResultProps {
  params: {
    [index: string]: string;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
const pageProps =
  <P extends ResultProps>(
    ComposedComponent: React.ComponentType<P>,
    // eslint-disable-next-line react/display-name
  ): FC<QueryProps> =>
  // eslint-disable-next-line react/display-name
  props => {
    const { location } = props;
    const params: { [index: string]: string } = {};

    if (location) {
      const { search } = location;

      if (search && search.length > 1) {
        search
          .slice(1)
          .split('&')
          .forEach(str => {
            const param = str.split('=');
            const [key, value] = param;
            params[key] = value;
          });
      }
    }

    return <ComposedComponent {...(props as P)} params={params} />;
  };

export default pageProps;
