import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { font, spacing } from '../../../styles/theme';
import Button from '../Button';
import Title from '../Title';

const Text = styled.div`
  margin: ${spacing.l} 0;
`;

const SignOn: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Title className="text-light-300" size={font.size.xl[0]}>
        {t('account.creation.title')}
      </Title>
      <Text>{t('account.creation.description')}</Text>
      <Button id="createAccount" status="light" to="/admin/account/create/">
        {t('account.creation.submit')}
      </Button>
    </div>
  );
};

export default SignOn;
