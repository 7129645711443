import { Form, SubmissionError, useStore } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { auth } from '../../../firebase';
import User from '../../../models/User';
import Button from '../../base/Button';
import Title from '../../base/Title';
import datas from './datas.json';

const LoginForm: FC = () => {
  const store = useStore();
  const { auth: authState } = store.getState();
  const location = authState?.pathname?.location;
  const { t } = useTranslation();

  const handleLogin = async (values: any) => {
    try {
      await User.signInWithEmailAndPassword(values.email, values.password);
    } catch (error) {
      console.error(error);
      await User.logout();
      throw new SubmissionError({ _error: 'auth.failed' });
    }

    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true);
      if (token) {
        User.login(token);
        // dispatch(setUser(user, true));
        navigate(location || process.env.GATSBY_REDIRECT_LOGED);
      }
    }
  };

  return (
    <div className="w-full">
      <Title as="h1" className="mb-6 text-xl">
        {t('account.login.title')}
      </Title>
      <Form
        datas={datas}
        footerProps={{
          className: 'mt-6',
        }}
        name="login"
        onSubmit={handleLogin}
        submitProps={{
          label: 'account.login.button',
        }}
      />
      <div className="mt-s">
        <div className="mt-m">
          <Button status="link" to="/password/">
            {t('account.password.lost.link')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
